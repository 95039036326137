import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import Feature from "@/components/Feature"
import BackgroundSection from "@/components/BackgroundSection"
import Quote from "@/components/Quote"

type PageProps = {
  data: any
}

const ScienceDepartmentRennovation: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/legacies.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Science Department"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">SCIENCE</h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Science Department Renovation" />
    <div className="container grid gap-8 lg:gap-x-4 md:grid-cols-2 py-24">
      <div>
        <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4">
          RBAI has enjoyed a{" "}
          <span className="text-primary">distinguished history</span> in the
          teaching of science, and continues to provide alternative{" "}
          <span className="text-primary">curriculum pathways</span> for
          Scientists
        </h2>
      </div>

      <div className="relative min-h-[300px] max-h-[360px] lg:pl-4">
        <img
          className="w-full h-full object-cover"
          src="/images/2018/03/science-block-1.jpg"
          alt="Science Department"
        />
      </div>
      <div>
        <p>
          Alongside the traditional disciplines: Chemistry, Biology and Physics,
          Triple Award Science has been introduced to enhance learning
          opportunities for our brightest pupils, some of whom also study GCSE
          Astronomy, which is taught outside the core school timetable.
        </p>
      </div>
      <div className="lg:pl-4">
        <p>
          Starting in 2018, a rolling renovation programme will see 12
          specialist laboratories upgraded through three successive summers,
          providing a first class learning environment.
        </p>
      </div>
    </div>

    <div className="container grid lg:grid-cols-2 gap-8 pb-24">
      <div>
        <img src="/images/2018/03/inno-5.jpg" alt="RBAI - Science Dept" />
      </div>
      <div>
        <img src="/images/2018/03/inno-6.jpg" alt="RBAI - Science Dept" />
      </div>
    </div>

    <hr />

    <div className="container py-24">
      <div className="flex flex-col gap-4 justify-between">
        <h2 className="responsive-heading">
          IMPACTING
          <br />
          <span className="text-primary">PUPILS</span>
        </h2>
        <p>The renovation of the science facilities will benefit pupils by:</p>
      </div>
      <div className="grid gap-y-8 md:grid-cols-3 pt-20">
        <Feature
          description=" Creating laboratories which are similar to commercial science
          laboratories, with a focus on greater collaboration, the use
          of technology, and open space learning."
        />
        <Feature
          description="Reinforcing RBAI’s position as a leading academic institution
          with an enviable track record in science."
        />
        <Feature description="Providing a stimulating teaching and learning environment." />
      </div>
    </div>

    <BackgroundSection
      heading={
        <h2>
          EXAM <br />
          <span className="text-primary">SUCCESS</span>
        </h2>
      }
      paragraphs={
        <>
          <p>
            <strong>2017</strong> – Angus Harley scored 600 out of 600 in A2
            Physics, the highest score in Northern Ireland. Having achieved
            third place in his A2 Mathematics during his lower sixth year (Year
            13), Angus is now reading Engineering at Jesus College, Cambridge.
            <p></p>
            <strong>2016</strong> – Omar Helmy achieved the highest A2 scores in
            Northern Ireland for Chemistry and Biology, enabling him to read
            Medicine at Jesus College, Cambridge.
          </p>
        </>
      }
      paragraphsClassName="max-w-[400px]"
      bg="/images/2018/03/science-3.jpg"
    />

    <div className="container grid md:grid-cols-2 pt-14 px-4">
      <Quote
        quote="During early lab work with my peers at Stanford, I was struck by
        how well we had been prepared at Inst. Having worked in a range of
        scientific laboratories around the world, I see that these
        proposals are perfect for future pupils."
        author={
          <span className="leading-[30px] py-2">
            <strong>Alastair Herron (RBAI 2006-13)</strong>
            <br />
            Achieved 7 A*s at A level, before studying Chemistry at Stanford
            University in California. Following graduation in 2017, Alastair
            then began work towards a PhD degree at the prestigious Scripps
            Research Institute in California, having received offers from
            Princeton, MIT, and Berkeley.ved the Institution of Civil Engineers
            (ICE) Emerging Engineers Award in 2016. He works for Skanska UK, a
            leading international project development and construction company.
          </span>
        }
        className="bg-[#111111]"
      />
    </div>
  </Layout>
)

export default ScienceDepartmentRennovation
