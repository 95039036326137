import clsx from "clsx"
import React from "react"

type Props = {
  title?: string
  description: string
  freeWidth?: boolean
}

const Feature: React.FC<Props> = ({
  title,
  description,
  freeWidth = false,
}) => {
  return (
    <div className="flex flex-col items-center text-center md:items-start md:text-start">
      <hr className="sep" />
      {title && <h3 className="uppercase">{title}</h3>}
      <p className={clsx("text-grey", { "max-w-[240px]": !freeWidth })}>
        {description}
      </p>
    </div>
  )
}

export default Feature
