import clsx from "clsx"
import React from "react"

type Props = {
  heading?: React.ReactElement
  paragraphs: React.ReactElement
  paragraphsClassName?: string
  bg: string
}

const BackgroundSection: React.FC<Props> = ({
  heading,
  paragraphs,
  bg,
  paragraphsClassName,
}) => {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url('${bg}')` }}
    >
      <div
        className={clsx("container text-white leading-none py-14 px-2", {
          "pt-24 sm:pt-52": !heading,
          "flex flex-col gap-24 sm:gap-52 md:gap-80": heading,
        })}
      >
        {heading}
        <div
          className={clsx(
            "flex flex-col gap-8 leading-relaxed",
            paragraphsClassName
          )}
        >
          {paragraphs}
        </div>
      </div>
    </div>
  )
}

export default BackgroundSection
