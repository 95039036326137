import clsx from "clsx"
import React from "react"

type Props = {
  quote: string
  author: string | React.ReactElement
  className?: string
}

const Quote: React.FC<Props> = ({ quote, author, className }) => {
  return (
    <div className={clsx("py-14 px-12 text-white", className)}>
      <p className="py-4 border-solid border-t-[5px] border-t-white text-[27px] uppercase">
        “{quote}”
      </p>
      <p className="py-2 border-solid border-y-2 border-y-white font-bold">
        {author}
      </p>
    </div>
  )
}

export default Quote
